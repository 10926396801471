<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <v-icon style="margin-right: 5px; margin-top: -5px">cake</v-icon>
            Students' Birthday List
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="this.form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td>{{ ++index }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.dob }}</td>
                <td class="text-xs-left">
                  {{
                    item.guardian_name && item.guardian_name != "null"
                      ? item.guardian_name
                      : ""
                  }}
                  <div
                    style="display: flex;"
                    v-if="item.mobile && item.mobile != 'null'"
                  >
                    <v-icon class="icon">settings_cell</v-icon>
                    <span style="font-size: 12px; color: #888">{{
                      item.mobile
                    }}</span>
                  </div>
                </td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <!--<v-btn color="primary" dark v-on="on">Left</v-btn>-->
                      <v-icon
                        style="cursor: pointer; color: #1976d2"
                        v-on="on"
                        @click="smsInfo(item)"
                        :disabled="!smsServiceStatus"
                        >sms
                      </v-icon>
                    </template>
                    <span>Send SMS</span>
                  </v-tooltip>
                  <!--<span style="font: 10px; color: red">SMS disabled</span>-->
                  <!--<v-icon style="cursor: pointer; color: #1976d2" @click="smsInfo(item)" v-if="smsServiceStatus">sms</v-icon>-->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="smsDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Send SMS </span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="smsForm"
              @submit.prevent="sendSMS"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="sendSMS"
              lazy-validation
            >
              <v-layout row wrap pt-2>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dense
                    autocomplete="off"
                    label="Mobile Number*"
                    required
                    class="pa-0 pl-2"
                    v-model="smsForm.mobile"
                    name="mobile"
                    hint="Mobile Number should be 10 digits."
                    :error-messages="form.errors.get('mobile')"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-textarea
                    outlined
                    dense
                    label="Message*"
                    :rows="6"
                    v-model="smsForm.message"
                  />
                  <p v-if="characterUsed > 0">
                    Character Used : {{ characterUsed }}, Parts :
                    {{ smsForm.sms_length }}
                  </p>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(smsDialog = false), smsForm.reset()"
              >Close</v-btn
            >
            <v-btn
              color="success"
              text
              @click="sendSMS"
              :disabled="
                !(
                  smsForm.mobile &&
                  smsForm.message &&
                  smsForm.mobile.length === 10
                )
              "
              >Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import SMS from "@/library/SMS";

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        description: "",
        company_id: "",
        parent_id: "",
      },
      "/api/report/notification/birthday"
    ),

    smsForm: new Form(
      {
        sms_type: "s",
        msg_type: 1,
        // file: '',
        mobile: "",
        message: "",
        sms_length: 1,
      },
      "/sms/api/sms/queue"
    ),

    sms: new SMS(),
    characterUsed: 0,
    partsUsed: 0,
    btnDisable: true,
    mobileValidate: false,
    btnText: "Send Single SMS",
    balance: 0,
    sms_queue: 0,
    rate_per_sms: 0,
    smsDialog: false,

    smsServiceStatus: false,

    searchAH: null,
    search: null,
    isLoading: false,
    pagination: {
      rowsPerPage: 10,
    },
    entries: [],
    accountHeads: [],
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Name", value: "name", align: "left", sortable: false },
      { text: "Grade", value: "grade", align: "left", sortable: false },
      { text: "Roll", value: "roll", align: "left", sortable: false },
      { text: "DOB", value: "dob", align: "left", sortable: false },
      { text: "Guardian", value: "guardian", align: "left", sortable: false },
      {
        text: "Send SMS",
        value: "guardian",
        align: "left",
        width: "160px",
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    "smsForm.message": function(value) {
      if (!value) this.btnDisable = true;
      else this.btnDisable = false;

      let smsDetail = { characters: 0, total_sms: 0 };
      if (value) smsDetail = this.sms.calculate(value);
      this.characterUsed = smsDetail.characters;
      this.smsForm.sms_length = smsDetail.total_sms;
      this.smsForm.msg_type = smsDetail.msg_type;
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&search=" +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    smsInfo(item) {
      if (item.mobile !== "null") this.smsForm.mobile = item.mobile;
      this.smsDialog = true;
    },
    sendSMS() {
      this.smsForm.fireFetch = false;
      this.smsForm
        .store()
        .then((data) => {
          this.smsDialog = false;
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        });
    },
  },
  mounted() {
    if (this.$auth.getServices("SMS")) this.smsServiceStatus = true;
    else this.smsServiceStatus = false;
    // console.log(this.$auth.getServices('SMS'));
  },
};
</script>
<style lang="scss">
.icon {
  font-size: 15px;
  color: black;
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)-$width /
      2 +
      px
      random($height)-$height /
      1.2 +
      px
      hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
  overflow: hidden;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation(
    (
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards
    )
  );
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
</style>
